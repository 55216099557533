<!--
 * @Author: nice
 * @Date: 2021-11-13 14:30:36
 * @LastEditTime: 2021-12-07 09:54:03
 * @LastEditors: nice
 * @Description: 欠费立即缴费
-->
<template>
  <section class="arrears-pay-at-once">
    <NavBar title="立即缴费" background="blue" />
    <van-notice-bar
      v-show="!platformStatus.enable"
      scrollable
      :text="this.platformStatus.msg"
      background="opacity"
      left-icon="volume-o"
    />
    <div class="main-height-x">
      <p class="self-cell price-num marginT10">
        <span>应缴金额</span>
        <span>{{ totalPrice }}元</span>
      </p>
      <p class="detail-info">
        <span>用户编号</span>
        <span>{{ meterInfo.customerNumber }}</span>
      </p>
      <p class="detail-info">
        <span>表号</span>
        <span>{{ meterInfo.deviceNumber }}</span>
      </p>
      <p class="detail-info address">
        <span>地址</span>
        <span>{{ meterInfo.fullAddress }}</span>
      </p>
      <p class="self-cell price-num marginT10">
        <span>缴费金额</span>
        <span>{{ totalPrice }}元</span>
      </p>
      <div class="btn-wrap-x">
        <van-button v-show="platformStatus.enable" type="info" class="self-btn" color="#5196FF" @click="payAtOnce">
          立即缴费
        </van-button>
      </div>
    </div>
  </section>
</template>

<script>
import { createPayOrder, getPlatformStatus, getTotalAmountByCustomerNumber } from '@/services/payServer.js'
import { Notify, Toast } from 'vant'
import { goToPayUrlWithWxCode } from '@/services/httpUrlConfig.js'

export default {
  data() {
    let { meterInfo = {}, keyword } = this.$route.query
    meterInfo = JSON.parse(meterInfo)
    return {
      meterInfo,
      userValue: keyword,
      totalPrice: 0,
      platformStatus: {
        enable: true, // 默认开启
        msg: '', // 当平台停用时的消息提示
      }, // 平台启用状态
    }
  },
  created() {
    this.hideshare()
    this.getTotalAmountByCustomerNumberData()
    this.loadPlatformStatus()
  },
  methods: {
    onBridgeReady() {
      WeixinJSBridge.call('hideOptionMenu')
    },
    hideshare() {
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
        }
      } else {
        this.onBridgeReady()
      }
    },
    // [根据用户号获取欠费总额]
    getTotalAmountByCustomerNumberData() {
      getTotalAmountByCustomerNumber({
        customerNumberId: this.meterInfo?.customerNumberId,
      }).then((res) => {
        if (+res.code === 9200) {
          this.totalPrice = res.data
        }
      })
    },
    payAtOnce() {
      // 充值按钮触发后校验结账时间
      getPlatformStatus().then((res) => {
        let platform = this.util.getTranscateRst(res)
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
        // 允许访问
        if (platform.state) {
          createPayOrder({
            // rechargeCount: 600,
            customerNumberId: this.meterInfo?.customerNumberId, // 用户号id
          }).then((res) => {
            if (+res.code === 9200) {
              const parames = {
                orderId: res.data,
                amount: this.totalPrice,
                customerNumber: this.meterInfo.customerNumber,
              }
              goToPayUrlWithWxCode(parames)
            } else {
              Toast(res.msg)
            }
          })
        } else {
          Notify({
            type: 'danger',
            message: this.platformStatus.msg,
          })
        }
      })
    },
    /**
     * 微信公众号是否可访问
     */
    loadPlatformStatus: function () {
      getPlatformStatus().then((res) => {
        let platform = this.util.getTranscateRst(res)
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
      })
    },
  },
}
</script>

<style lang="less">
.padding {
  padding: 16px 20px;
}
.arrears-pay-at-once {
  .self-cell {
    background-color: #fff;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
    .padding;
  }
  .detail-info {
    background-color: #fff;
    padding: 5px 20px;
    display: flex;
    justify-content: space-between;
    color: #333;
  }
  .address {
    padding-bottom: 20px;
  }
  .btn-wrap-x {
    margin-top: 20px;
    padding: 0 16px;
    .self-btn {
      height: 40px;
      width: 100%;
    }
  }
}
</style>
